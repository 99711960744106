enum Types {
  AUTH_CHANGE_FIELD = 'AUTH_CHANGE_FIELD',
  AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST',
  AUTH_LOGIN_FULFILLED = 'AUTH_LOGIN_FULFILLED',
  AUTH_LOGIN_REJECTED = 'AUTH_LOGIN_REJECTED',
  AUTH_FORGOT_PASSWORD_REQUEST = 'AUTH_FORGOT_PASSWORD_REQUEST',
  AUTH_FORGOT_PASSWORD_FULFILLED = 'AUTH_FORGOT_PASSWORD_FULFILLED',
  AUTH_FORGOT_PASSWORD_REJECTED = 'AUTH_FORGOT_PASSWORD_REJECTED',

  AUTH_CHANGE_PASSWORD_REQUEST = 'AUTH_CHANGE_PASSWORD_REQUEST',
  AUTH_CHANGE_PASSWORD_FULFILLED = 'AUTH_CHANGE_PASSWORD_FULFILLED',
  AUTH_CHANGE_PASSWORD_REJECTED = 'AUTH_CHANGE_PASSWORD_REJECTED',

  AUTH_CHECK_RESTRICTIONS_REQUEST = 'AUTH_CHECK_RESTRICTIONS_REQUEST',
  AUTH_CHECK_RESTRICTIONS_FULFILLED = 'AUTH_CHECK_RESTRICTIONS_FULFILLED',
  AUTH_CHECK_RESTRICTIONS_REJECTED = 'AUTH_CHECK_RESTRICTIONS_REJECTED',

  AUTHENTICATE_SSO_REQUEST = 'AUTHENTICATE_SSO_REQUEST',
  AUTHENTICATE_SSO_FULFILLED = 'AUTHENTICATE_SSO_FULFILLED',
  AUTHENTICATE_SSO_REJECTED = 'AUTHENTICATE_SSO_REJECTED',

  AUTH_LOGIN_CISCO_DUO_REQUEST = 'AUTH_LOGIN_CISCO_DUO_REQUEST',
  AUTH_LOGIN_REQUIRED_MFA_REQUEST = 'AUTH_LOGIN_REQUIRED_MFA_REQUEST',
  AUTH_LOGIN_REQUIRED_MFA_FULFILLED = 'AUTH_LOGIN_REQUIRED_MFA_FULFILLED',
  AUTH_LOGIN_REQUIRED_MFA_REJECTED = 'AUTH_LOGIN_REQUIRED_MFA_REJECTED',

  GET_TOTP_QRCODE_REQUEST = 'GET_TOTP_QRCODE_REQUEST',
  GET_TOTP_QRCODE_FULFILLED = 'GET_TOTP_QRCODE_FULFILLED',
  GET_TOTP_QRCODE_REJECTED = 'GET_TOTP_QRCODE_REJECTED',

  AUTH_LOGIN_TOTP_QRCODE_REQUEST = 'AUTH_LOGIN_TOTP_QRCODE_REQUEST',
  AUTH_LOGIN_TOTP_QRCODE_FULFILLED = 'AUTH_LOGIN_TOTP_QRCODE_FULFILLED',
  AUTH_LOGIN_TOTP_QRCODE_REJECTED = 'AUTH_LOGIN_TOTP_QRCODE_REJECTED',

  AUTH_UPDATE_PLUGIN = 'AUTH_UPDATE_PLUGIN',

  AUTH_REGISTER_REQUEST = 'AUTH_REGISTER_REQUEST',
  AUTH_REGISTER_FULFILLED = 'AUTH_REGISTER_FULFILLED',
  AUTH_REGISTER_REJECTED = 'AUTH_REGISTER_REJECTED',
  AUTH_CLEAR_FIELDS = 'AUTH_CLEAR_FIELDS',

  AUTH_LOGIN_WITH_SSO_REQUEST = 'AUTH_LOGIN_WITH_SSO_REQUEST',
  AUTH_LOGIN_WITH_SSO_FULFILLED = 'AUTH_LOGIN_WITH_SSO_FULFILLED',
  AUTH_LOGIN_WITH_SSO_REJECTED = 'AUTH_LOGIN_WITH_SSO_REJECTED',

  AUTH_LOGOUT_REQUEST = 'AUTH_LOGOUT_REQUEST',
  AUTH_LOGOUT_FULFILLED = 'AUTH_LOGOUT_FULFILLED',
  AUTH_LOGOUT_REJECTED = 'AUTH_LOGOUT_REJECTED',

  AUTH_LOGOUT = 'CLEAR_STORE',

  ENABLE_AUTO_LOGIN = 'ENABLE_AUTO_LOGIN',
  DISABLE_AUTO_LOGIN = 'DISABLE_AUTO_LOGIN',

  AUTH_CHECK_CHANGE_PASSWORD_REQUEST = 'AUTH_CHECK_CHANGE_PASSWORD_REQUEST',
  AUTH_CHECK_CHANGE_PASSWORD_FULFILLED = 'AUTH_CHECK_CHANGE_PASSWORD_FULFILLED',
  AUTH_CHECK_CHANGE_PASSWORD_REJECTED = 'AUTH_CHECK_CHANGE_PASSWORD_REJECTED',
}

export default Types;
