import { handleActions } from 'redux-actions';
import Types from './types';
import { ConfigState } from './interfaces';

const initialState: ConfigState = {
  primary: {
    contrastText: '#5b52c4',
    dark: '#000A62',
    main: '#000A62',
    light: '#000A62',
  },
  secondary: {
    contrastText: '#357FFA',
    dark: '#357FFA',
    main: '#357FFA',
    light: '#357FFA',
  },
  primaryColor: '#000A62',
  secondaryColor: '#357FFA',
  backgroundColor: '#0064af',
  textColorHover: '#FFF',
  imageLoginBackgroundColor: '#F7B528',
  logoBackgroundColor: '#fafafa',
  imageUrl: '/images/background.png',
  logoUrl: '/images/SkyoneAutoskyLogoHR.png',
  headerLoginFontSize: '45px',
  descriptionLoginFontSize: '16px',
  headerLoginTextPtBr: 'Seja bem-vindo!',
  descriptionLoginTextPtBr: 'Faça seu login para iniciar o acesso ao seu sistema de forma online, com dados criptografados e armazenados em ambientes seguros!',
  headerLoginTextEn: 'Welcome!',
  descriptionLoginTextEn: 'Log in to access your system online, with encrypted data stored in secure environments!',
  headerLoginTextEs: '¡Bienvenido!',
  descriptionLoginTextEs: 'Inicie sesión para acceder a su sistema en línea, con datos encriptados y almacenados en entornos seguros!',
  headerMfaFontSize: '45px',
  descriptionMfaFontSize: '16px',
  headerMfaTextPtBr: 'Seja bem-vindo!',
  descriptionMfaTextPtBr: 'Com a plataforma Autosky, você leva seu software monolítico para a nuvem, sem mexer em nenhuma linha de código, com banco de dados e usuários configurados em poucas horas.',
  headerMfaTextEn: 'Welcome!',
  descriptionMfaTextEn: 'With the Autosky platform, you can move your monolithic software to the cloud without modifying a single line of code, with databases and users set up in just a few hours.',
  headerMfaTextEs: '¡Bienvenido!',
  descriptionMfaTextEs: 'Con la plataforma Autosky, puede llevar su software monolítico a la nube sin modificar ni una sola línea de código, con bases de datos y usuarios configurados en pocas horas.',
  maintenanceImage: '/images/newMaintenance.png',
  messagesImage: '/images/newYellow.png',
  messageTextColor: '#E1AF44',
  title: 'Skyone Autosky',
  favicon: '/favicon.png',
  activeDirectoryType: 'interno',
  defaultBannerOption: 0,
  reload: true,
  showNews: false,
  appColor: '#000A62',
  detailsLoginColor: '#d9d9d9',
};

export default handleActions(
  {
    [Types.CONFIG_REQUEST]: (state: ConfigState) => ({
      ...state,
      reload: true,
    }),
    [Types.CONFIG_FULFILLED]: (state: ConfigState, { payload }) => ({
      ...state,
      ...payload,
      reload: false,
    }),
    [Types.CONFIG_CHANGE_SHOW_NEWS]: (state: ConfigState, { payload }) => ({
      ...state,
      showNews: payload,
    }),
  },
  initialState
);
