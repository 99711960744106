import Apis from 'apis';

export const api = Apis({
  baseURL: process.env.REACT_APP_URL || '',
});

export default {
  login: () => `/login`,
  loginOtp: () => `/login_otp`,
  totpQrcode: () => `/totp_qrcode`,
  resetPassword: () => '/reset_password',
  changePassword: () => '/change_password',
  register: () => '/register_password',
  loginSSO: () => `/sso/login`,
  tokenValidateSSO: () => `/token/validate`,
  checkRestrictions: () => '/user/check_password_restrictions',
  logoutSSO: () => 'sso/logout',
  loginCiscoDuo: () => '/duo_callback',
  authCheckChangePassword: () => '/check_password_restrictions_with_login',
};
