import { createAction } from 'redux-actions';
import { history } from 'store';
import Types from './types';

export const authChangeFields = createAction(Types.AUTH_CHANGE_FIELD);
export const authLoginRequest = createAction(Types.AUTH_LOGIN_REQUEST);
export const authLoginFulfilled = createAction(Types.AUTH_LOGIN_FULFILLED);
export const authLoginRejected = createAction(Types.AUTH_LOGIN_REJECTED);

export const authForgotPasswordRequest = createAction(
  Types.AUTH_FORGOT_PASSWORD_REQUEST
);

export const authLoginCiscoDuoRequest = createAction(
  Types.AUTH_LOGIN_CISCO_DUO_REQUEST
);
export const authLoginRequiredMFARequest = createAction(
  Types.AUTH_LOGIN_REQUIRED_MFA_REQUEST
);
export const authLoginRequiredMFAFulfilled = createAction(
  Types.AUTH_LOGIN_REQUIRED_MFA_FULFILLED
);
export const authLoginRequiredMFARejected = createAction(
  Types.AUTH_LOGIN_REQUIRED_MFA_REJECTED
);

export const checkLoginSSORequest = createAction(
  Types.AUTH_LOGIN_WITH_SSO_REQUEST
);
export const keepLoginSSOFulfilled = createAction(
  Types.AUTH_LOGIN_WITH_SSO_FULFILLED
);
export const keepLoginSSOReject = createAction(
  Types.AUTH_LOGIN_WITH_SSO_REJECTED
);

export const authCheckRestrictionsRequest = createAction(
  Types.AUTH_CHECK_RESTRICTIONS_REQUEST
);
export const authCheckRestrictionsFulfilled = createAction(
  Types.AUTH_CHECK_RESTRICTIONS_FULFILLED
);
export const authCheckRestrictionsRejected = createAction(
  Types.AUTH_CHECK_RESTRICTIONS_REJECTED
);

export const authenticateSSORequest = createAction(
  Types.AUTHENTICATE_SSO_REQUEST
);
export const authenticateSSOFulfilled = createAction(
  Types.AUTHENTICATE_SSO_FULFILLED
);
export const authenticateSSORejected = createAction(
  Types.AUTHENTICATE_SSO_REJECTED
);

export const getTotpQrcodeRequest = createAction(Types.GET_TOTP_QRCODE_REQUEST);
export const getTotpQrcodeFulfilled = createAction(
  Types.GET_TOTP_QRCODE_FULFILLED
);
export const getTotpQrcodeRejected = createAction(
  Types.GET_TOTP_QRCODE_REJECTED
);

export const authforgotPasswordFulfilled = createAction(
  Types.AUTH_FORGOT_PASSWORD_FULFILLED
);
export const authforgotPasswordRejected = createAction(
  Types.AUTH_FORGOT_PASSWORD_REJECTED
);

export const authChangePasswordRequest = createAction(
  Types.AUTH_CHANGE_PASSWORD_REQUEST
);
export const authChangePasswordFulfilled = createAction(
  Types.AUTH_CHANGE_PASSWORD_FULFILLED
);
export const authChangePasswordRejected = createAction(
  Types.AUTH_CHANGE_PASSWORD_REJECTED
);

export const authRegisterRequest = createAction(Types.AUTH_REGISTER_REQUEST);

export const authRegisterFulfilled = createAction(
  Types.AUTH_REGISTER_FULFILLED
);
export const authRegisterRejected = createAction(Types.AUTH_REGISTER_REJECTED);

export const authUpdatePlugin = createAction(Types.AUTH_UPDATE_PLUGIN);

export const authClearFields = createAction(Types.AUTH_CLEAR_FIELDS);

export const authLogoutRequest = createAction(Types.AUTH_LOGOUT_REQUEST);
export const authLogoutFulfilled = createAction(Types.AUTH_LOGOUT_FULFILLED);
export const authLogoutRejected = createAction(Types.AUTH_LOGOUT_REJECTED);

export const authLogout = () => {
  history.push('/');
  return {
    type: Types.AUTH_LOGOUT,
  };
};

export const enableAutoLogin = createAction(Types.ENABLE_AUTO_LOGIN);
export const disableAutoLogin = createAction(Types.DISABLE_AUTO_LOGIN);

export const authCheckChangePasswordRequest = createAction(
  Types.AUTH_CHECK_CHANGE_PASSWORD_REQUEST
);
export const authCheckChangePasswordFulfilled = createAction(
  Types.AUTH_CHECK_CHANGE_PASSWORD_FULFILLED
);
export const authCheckChangePasswordRejected = createAction(
  Types.AUTH_CHECK_CHANGE_PASSWORD_REJECTED
);
