/* eslint-disable indent */
import { takeLatest, put, all, select } from 'redux-saga/effects';
import { isRequestOK } from 'utils/helpers';
import { push } from 'connected-react-router';
import {
  clientsRequest,
  clientsRejected,
  clientsFulfilled,
  selectedClient,
  selectedClientFulfilled,
  selectedClientRejected,
  clientLoginAttemptRequest,
  clientLoginAttemptFulfilled,
  clientLoginAttemptRejected,
  clientSessionRequest,
  registerSelectedVirtualizerRejected,
  registerSelectedVirtualizerRequest,
  registerSelectedVirtualizerFulfilled,
  registerSelectedVirtualizerRequestCompleted,
  registerSelectedVirtualizerLinuxRequest,
  getWebAppsFulfilled,
  getWebAppsRejected,
  getWebAppsRequest,
} from './actions';
import endpoints, { api } from './endpoints';
import { pluginHandle } from './utils';
import { messagesRequest } from 'modules/messages/actions';
import i18n from 'i18n';
import { authLogout } from 'modules/auth/actions';

function* clientsRequestSaga() {
  try {
    const {
      auth: { shouldUpdatePlugin },
    } = yield select();

    const { status, data } = yield api.get({
      url: endpoints.selectClients(),
    });

    if (yield isRequestOK(status)) {
      yield put(clientsFulfilled(data.clients));
      if (data?.clients?.length === 1) {
        yield put(selectedClient(data.clients[0].client_uuid));
        yield push('/launching/1');
      } else {
        const pushUrl = shouldUpdatePlugin
          ? '/updatePlugin'
          : '/home/environments';
        yield put(push(pushUrl));
      }
    } else {
      yield put(clientsRejected());
    }
  } catch (responseWithError) {
    return yield responseWithError &&
    responseWithError.response &&
    responseWithError.response.status === 452
      ? put(clientsRejected())
      : all([put(clientsRejected())]);
  }
}

function* selectedClientSaga() {
  try {
    const {
      clients: { uuid, clients },
    } = yield select();

    if (!uuid) {
      yield put(
        messagesRequest({
          type: 'error',
          message: i18n.t('ERROR_TO_ACCESS_ENVIRONMENT_KEY'),
        })
      );
      return yield clients.length > 1
        ? put(push('/home/environments'))
        : put(authLogout());
    }

    const { status, data } = yield api.get({
      url: endpoints.clientSelected(uuid),
    });

    type MakeRequestType = {
      client?: string;
      environment?: string;
    };

    let makeRequest: MakeRequestType = {};

    if (Object.keys(data?.warning_message).length > 0) {
      makeRequest = data?.warning_message as MakeRequestType;
    } else if (Object.keys(data?.maintenance_message).length > 0) {
      makeRequest = data?.maintenance_message as MakeRequestType;
    }

    return yield isRequestOK(status)
      ? all([
          put(selectedClientFulfilled(data)),
          makeRequest.client || makeRequest.environment
            ? put(push('/messages'))
            : '',
          !(makeRequest.client || makeRequest.environment)
            ? put(clientLoginAttemptRequest())
            : '',
        ])
      : all([put(selectedClientRejected())]);
  } catch (responseWithError) {
    return yield responseWithError &&
    responseWithError.response &&
    responseWithError.response.status === 452
      ? put(selectedClientRejected())
      : all([put(selectedClientRejected())]);
  }
}

function* clientLoginAttemptRequestSaga() {
  try {
    const {
      clients: { uuid },
    } = yield select();

    const { status, data } = yield api.get({
      url: endpoints.loginAttempt(uuid),
    });

    yield put(clientLoginAttemptFulfilled(data));
    yield pluginHandle(data, status, uuid);

    return yield isRequestOK(status)
      ? all([
          status === 202 ? put(push('/timeInstance')) : '/home/environments',
        ])
      : all([put(clientLoginAttemptRejected())]);
  } catch (responseWithError) {
    if (responseWithError) {
      if (responseWithError?.response.data.restricted_type) {
        return yield all([
          put(push('/accessRestricted')),
          put(clientLoginAttemptFulfilled(responseWithError?.response)),
        ]);
      }
      return yield responseWithError?.response?.status === 452
        ? put(clientLoginAttemptRejected())
        : all([put(clientLoginAttemptRejected())]);
    }
  }
}

function* clientSessionRequestSaga() {
  try {
    const {
      clients: { uuid },
    } = yield select();

    const { data } = yield api.get({
      url: endpoints.userSession(uuid),
    });

    if (!data.is_active_session) {
      return yield all([put(clientLoginAttemptRequest())]);
    }
  } catch (responseWithError) {
    return yield put(
      messagesRequest({
        type: 'error',
        message: i18n.t('SOMETHING_BAD_HAPPENED'),
      })
    );
  }
}

function* registerSelectedVirtualizerRequestSaga({ payload }) {
  const { data, promiseHandlers = {} } = payload;
  const { resolve, reject } = promiseHandlers;

  try {
    const response = yield api.post({
      url: endpoints.registerSelectedVirtualizer(),
      data: data,
    });

    yield all([
      put(registerSelectedVirtualizerFulfilled(response.data)),
      put(registerSelectedVirtualizerRequestCompleted()),
    ]);

    if (resolve) resolve();
  } catch (e) {
    yield all([
      put(registerSelectedVirtualizerRejected()),
      put(registerSelectedVirtualizerRequestCompleted()),
    ]);

    if (reject) reject(e);
  }
}

function* registerSelectedVirtualizerLinuxRequestSaga({ payload }) {
  try {
    const { data } = yield api.post({
      url: endpoints.registerSelectedVirtualizer(),
      data: payload,
    });

    return yield all([
      put(registerSelectedVirtualizerFulfilled(data)),
      put(registerSelectedVirtualizerRequestCompleted()),
    ]);
  } catch (e) {
    return yield all([
      put(registerSelectedVirtualizerRejected()),
      put(registerSelectedVirtualizerRequestCompleted()),
    ]);
  }
}

function* getWebAppsRequestSaga({ payload }) {
  const { id } = payload;
  try {
    const { data } = yield api.get({
      url: endpoints.getWebApps(id),
    });
    return yield all([put(getWebAppsFulfilled(data))]);
  } catch (e) {
    return yield all([put(getWebAppsRejected())]);
  }
}

export default [
  takeLatest(clientsRequest, clientsRequestSaga),
  takeLatest(selectedClient, selectedClientSaga),
  takeLatest(clientLoginAttemptRequest, clientLoginAttemptRequestSaga),
  takeLatest(clientSessionRequest, clientSessionRequestSaga),
  takeLatest(
    registerSelectedVirtualizerRequest,
    registerSelectedVirtualizerRequestSaga
  ),
  takeLatest(
    registerSelectedVirtualizerLinuxRequest,
    registerSelectedVirtualizerLinuxRequestSaga
  ),
  takeLatest(getWebAppsRequest, getWebAppsRequestSaga),
];
