/* eslint-disable max-lines */

export default {
  translation: {
    VERSION_KEY: 'Version',
    PORTUGUESE_KEY: 'Portuguese',
    ENGLISH_KEY: 'English',
    SPANISH_KEY: 'Spanish',
    SELECT_CLIENT_KEY: 'Select a client to continue',
    HOME_KEY: 'Autosky',
    ENVIRONMENTS_KEY: 'Environments',
    MAXIMUM_LOGIN_ATTEMPTS:
      'Inactive user: You have exceeded the maximum number of login attempts. Please contact the administrator!',
    RECAPTCHA_ERROR_KEY: 'Error in recaptcha validation. Try again',
    ACTIONS_KEY: 'Actions',
    CLIENTS_NAME_KEY: 'Clients',
    MAINTENANCE_KEY: 'Maintenance',
    TIME_INSTANCE_KEY: 'Finding the instance',

    FIRST_ACCESS_KEY: 'Welcome.',
    FIRST_ACCESS_SECOND_KEY:
      'Is this your first access to the Autosky platform?',
    CLICK_HERE_TO_CONNECT_KEY: 'Click here to connect',
    ADD_PLUGIN_KEY:
      'To start your session correctly, install the Connection Plugin on your computer.',

    HAS_NO_PLUGIN_KEY:
      'If this is your first access, you must install the connection plugin.',
    HAS_INSTALLED_PLUGIN_KEY: 'Have you already installed the plugin?',
    YES_KEY: 'Yes',
    NO_KEY: 'No',
    PENDING_CONNECTIONS_KEY: 'Pending Connections',
    TITLE_FORGOT_PASSWORD_KEY: 'Forgot password?',
    FORGOT_PASSWORD_KEY: 'Forgot my password',
    FORGOT_PASSWORD_TEXT_2_KEY: 'Enter your email address below',
    FORGOT_PASSWORD_TEXT_KEY: 'Fill the data to change your password.',
    FORGOT_PASSWORD2_TEXT_KEY: 'Do not use easy passwords or only numbers',
    BACK_KEY: 'Back',
    SEND_KEY: 'Send',
    CHANGE_KEY: 'Change',
    RECOVER_KEY: 'Recover',
    WELCOME_KEY: 'Welcome!',
    DESCRIPTION_LOGIN_KEY:
      'Log in to start accessing your system online, with data encrypted and stored in secure environments!',
    FORGOT_PASSWORD_HEADER_KEY: `Password Recovery`,
    CHANGE_PASSWORD_TITLE_KEY: `New Password`,
    FORGOT_PASSWORD_DESCRIPTION_KEY:
      'Enter your email and click Recover to receive a password reset email.',
    PRIVACY_POLICY_KEY: 'Privacy Policy',
    CODE_OF_ETHICS_AND_CONDUCT_KEY: 'Terms and Conditions',
    NEW_PASSWORD_KEY: 'Register your password',
    NEW_PASSWORD_KEY_2: 'New Password',
    NEW_PASSWORD_DESCRIPTION_KEY:
      'Register your new password beside it. Use letters, numbers and symbols for added security.',
    HEADER_LOGIN_KEY: 'Fast and Safe.',
    EMAIL_KEY: 'E-mail',
    OLD_PASSWORD_KEY: 'Old Password',
    PASSWORD_KEY: 'Password',
    CONFIRM_PASSWORD_KEY: 'Confirm Password',
    CONFIRM_NEW_PASSWORD_KEY: 'Confirm new password',
    NO_CLIENTS_KEY: 'No Clients Found!',
    CONNECT_KEY: 'Connect',
    SEARCH_KEY: 'Search',
    SEARCH_ENVIRONMENT_KEY: 'Type here to find an environment',
    YOU_HAVE_KEY: 'You have a total of ',
    ENVIRONMENT_IN_AUTO_SKY_KEY: '',
    SOMETHING_BAD_HAPPENED: 'Something bad happened. Try again.',
    INITIATING_AUTO_SKY_KEY: 'Starting your user session...',
    THIS_CAN_TAKE_KEY: 'This procedure can take up to',
    EIGHT_MINUTES_KEY: ' 8 minutes;',
    FIVE_MINUTES_KEY: ' 5 minutes;',
    TWO_MINUTES_KEY: ' 2 minutes;',
    UPDATE_KEY: 'Update',
    YOU_WILL_BE_REDIRECT_KEY: 'You will be automatically redirected',
    OPS_KEY: 'Your environment is under maintenance',
    HERE_ENVIRONMENT_KEY: 'Select an environment to access the system. ',
    MAINTENANCE_RUNNING_KEY:
      'The selected environment is unavailable or under maintenance by the system administrators.',
    ACCESS_KEY: 'Access',
    NO_ITEMS_FOUND: 'No environments found with your search criteria',
    SESSION_ACTIVE_1_KEY:
      'We have identified that there is an active session for your user.',
    SESSION_ACTIVE_2_KEY:
      'Would you like to end your old session and continue?',
    WHY_YOU_RECEIVE_THIS_KEY: 'Why am I seeing this?',
    SESSION_IDENTIFIED:
      'You may have suffered a momentary internet disconnection or not closed your previous connection properly.',
    FINALIZE_PREVIOUS_SESSION:
      'If this scenario persists, please contact your system administrator.',
    SESSION_ACTIVE_3_KEY:
      'Click on CONTINUE to terminate that session and proceed.',
    CONTINUE_KEY: 'Continue',
    WAIT_A_MOMENT_KEY:
      "Wait a moment and don't worry, you'll be automatically logged in as soon as we're ready.",
    LOCATING_ENVIRONMENT: 'We are locating the best space for your access.',
    REQUIRED_FIELD: 'Required Field!',
    ENTER_KEY: 'Sign in',
    CHANGE_PASSWORD_KEY: 'Change my password',
    CANCEL_KEY: 'Cancel',
    VIRTUALIZER_CHOICE_KEY: 'Virtualizer',
    MAX_LENGTH_PASSWORD: 'Maximum characters for the password is 40.',

    WARNING_MESSAGE: 'Warning message:',
    USER_NOT_FOUND:
      'Invalid e-mail and/or password. Please enter the data again.',
    WRONG_USER_KEY: 'User not found.',
    ERROR_REQUEST_KEY: 'Something went wrong. Please try again.',
    VERIFY_YOUR_EMAIL_KEY: 'A message was sent to your e-mail',
    UPDATED_ACCOUNT_KEY: 'Password updated successfully.',
    EMAIL_OR_PASSWORD_DONT_MATCH_KEY:
      'E-mail or password not found or do not match',
    EMAIL_INVALID_KEY: 'Invalid e-mail format!',
    TYPE_YOUR_NEW_PASSWORD_KEY: 'Type your new password',
    ERROR_CHANGING_PASSWORD_KEY: 'Error while trying to change your password.',
    ENVIRONMENT_NOT_CONFIGURED_KEY:
      'There are no more licenses available for this environment. Look for your system administrator.',
    VIRTUALIZER_NOT_FOUND: 'Virtualizer not found.',
    UPDATE_PLUGIN_KEY: 'Connection plugin update',
    UPDATE_YOUR_PLUGIN_KEY: 'Update your plugin',
    IF_DIALOG_NOT_SHOWING_KEY: 'If the dialog box still does not appear, ',
    GO_TO_INSTALL_KEY: 'click here to follow the installation steps ',
    PLUGIN_IN_COMPUTER_KEY: 'to install the plugin on your computer.',
    UPDATE_YOUR_PLUGIN_TEXT_KEY:
      'Hello, there is a connection plugin update for you to continue with your connection.',
    UPDATE_YOUR_PLUGIN_TEXT2_KEY2:
      'Click on the CONTINUE button to proceed to the update.',
    FOLLOW_THE_STEPS_BELOW_KEY: 'Follow the steps below:',
    MIN_KEY: 'Minimum of {{value}} characters',
    MAX_KEY: 'Maximum of {{value}} characters',
    PASSWORD_DOESNT_MATCH: 'The passwords do not match.',
    PASSWORD_MUST_BE_DIFFERENT:
      'The new password must be different from the old password.',

    PASSWORD_MUST_CONTAIN_KEY: 'Password must contain',
    REQUIRE_UPPERCASE_TEXT_KEY: 'Capital letters',
    REQUIRE_LOWERCASE_TEXT_KEY: 'Small letters',
    REQUIRE_NUMBER_TEXT_KEY: 'Numbers',
    REQUIRE_SYMBOL_TEXT_KEY: 'Special characters',
    REQUIRE_PASSWORD_DIFF_KEY:
      'The new password must be different from the old password',
    REQUIRE_MIN_LENGTH_TEXT_KEY: 'Minimum of {{min_length}} characters',
    REQUIRE_MAX_LENGTH_TEXT_KEY: 'Maximum of {{max_length}} characters',

    NO_LICENSE_CLIENT: 'The license limit has been reached.',
    LAUNCHING_KEY: 'Launching...',
    PLEASE_CLICK_KEY: 'Click on the',
    AUTO_SKY_PLUGIN_KEY: 'Autosky Plugin',
    SEE_DIALOG_KEY: 'to start your session.',
    IF_NOT_KEY: 'If the dialog box does not appear,',
    CLICK_HERE_KEY: 'click here ',
    LAUNCH_KEY: 'to display the window again.',
    DOWNLOAD_AND_RUN_KEY: 'click here to follow the installation steps.',
    STARTING_HTML5_INSTANCE: 'Your session is being set up...',
    SUB_TEXT_LOADING_VIRTUALIZER_KEY:
      'Bring your systems and client-server applications to the cloud.',
    LOADING_KEY: 'Loading',
    ESTABLISHING_CONNECTION_KEY: 'Establishing the connection',

    CONNECTION_FINISHED_KEY: 'Connection established!',
    SUCCESS_LOADING_IF_NOT_KEY: 'If your application has not started yet',
    SUCCESS_LOADING_CONFIRM_KEY: 'to confirm the installation of the plugin',

    CHOOSE_VIRTUALIZER_KEY: 'Select your preferred access method:',
    CHOOSE_VIRTUALIZER_SUB_TEXT_KEY:
      'By selecting the desired option, you will be directed to the connection of your environment.',
    PC_KEY: 'Computer',
    BROWSER_KEY: 'Browser',
    OPEN_AT_KEY: 'Open at {{content}}',

    INSTALL_KEY: 'Install',

    INSTALL_PLUGIN: {
      INSTALLATION_KEY: 'Autosky Connection Plugin Installation',
      STEP_TO_STEP_KEY: 'Installation instructions:',
      IF_NOT_KEY: 'If the download did not start automatically, ',
      IN_CASE_APPLICATION_DIDNT_APPEAR_ONE:
        'In case your application has not been loaded, ',
      CONFIRM_INSTALLATION: 'to confirm the plugin installation',
      TO_CONTINUE_KEY: 'to download the file manually',

      FIRST_STEPS: {
        ONE: '01. Click on "Install";',
        TWO:
          '02. You will be redirected to the screen with the instructions to install the plugin;',
        THREE:
          '03. After you activate the extension, close the window and install the executable file.',
      },

      LAST_STEPS: {
        ONE: '01. Confirm the automatic download of the installation file',
        TWO: '02. Run the installation file on your Windows or MAC computer',
        THREE:
          '03. Once the installation has been completed on your computer, click NEXT',
      },
    },

    NEXT_KEY: 'Next',

    NOT_FOUND_PAGE_KEY: 'Page not found',
    GO_TO_HOME_KEY: 'Go to home',

    DESCRIPTION_MFA_KEY:
      'With the Autosky platform, you can take your monolithic software to the cloud, without touching a single line of code, with database and users configured in a few hours',
    TURN_BACK_KEY: 'Return',
    AUTHENTICATION_CODE_KEY: 'Enter the authentication code',
    PANEL_AUTO_SKY: 'Autosky Panel',
    CONFIGURE_MFA_KEY: 'Configure your MFA authentication code.',
    INSERT_MFA_KEY: 'Enter your MFA authentication code',

    MODAL_VERSION_TITLE_KEY: 'Software update available',
    MODAL_VERSION_CONTENT_KEY:
      'There is an update available for your application. Click to update without losing your current session',
    MODAL_BUTTON_KEY: 'Update version',

    NOT_INPUT_DATA: 'No data provided',
    USER_NOT_ACTIVE: 'User is not active',
    INVALID_EMAIL: 'Invalid email',
    INVALID_PASSWORD: 'Invalid password',
    USER_AUTHENTICATED: 'Authenticated User',
    AUTH_FAILURE: 'Authentication failed. Please, try again.',
    PASSWORD_EXPIRED: 'The password has expired. Redefine a new password',
    PASSWORD_EXPIRES_SOON: 'Password will expire soon.',
    UNABLE_UPDATE_PASSWORD: 'Unable to update password.',
    ERROR_UPDATING_PASSWORD: 'Error updating password.',
    PASSWORD_UPDATED: 'Password successfully updated!',
    INVALID_TYPE: 'Invalid type',
    NOT_NULL: 'This field cannot be null.',
    VALIDATOR_FAILED: 'Incorrect validation.',
    INVALID_VALUE: 'Invalid value',
    ERROR_IN_ACCESS_VALIDATION: 'Error validating access in Active Directory.',
    EXTERNAL_USER: 'External User',
    SUCCESS: 'Success',
    HAS_PENDING_CONNECTION: 'You have pending connections',
    PASSWORD_CHANGE_ERROR: 'Error changing password.',
    PASSWORD_CHANGE_SUCCESS: 'Password changed successfully!',
    CONTACT_YOUR_SYSTEM_ADMINISTRATOR:
      'Contact your administrator to change your password',
    EXPIRED_MAIL: 'Email expired.',
    ERROR_TO_SEND_DISCONNECT:
      'Error disconnecting users with pending connections.',
    ENVIRONMENT_MAINTENANCE_WITHOUT_MESSAGE:
      'Maintenance message not configured for environment.',
    USER_NO_OTP_DATA: 'OTP is not configured for your user.',
    USER_OTP_ALREADY_CONFIGURED: 'OTP is already configured for your user.',
    USER_OTP_INVALID: 'Invalid OTP.',
    CLIENT_MAINTENANCE_WITHOUT_MESSAGE:
      'Maintenance message not configured for client.',
    ENVIRONMENT_WARNING_WITHOUT_MESSAGE:
      'Warning message not configured for environment',
    CLIENT_WARNING_WITHOUT_MESSAGE: 'Warning message not configured for client',
    UNSENT_EMAIL: 'Email not sent',
    CLIENT_NOT_FOUND: 'Client not found',
    CALL_CLIENT_PLUGIN: 'Call to client plugin',
    SOMETHING_WENT_WRONG: 'Something went wrong. Try again.',
    PAYLOAD_NOT_RIGHT: 'Data sent in the payload is not correct.',
    INVALID_RECAPTCHA: 'Invalid Recaptcha. Try again.',
    IDP_NOT_FOUND: 'Identity Provider not found',
    IDP_AUTH_LOGIN_REDIRECT_ERROR:
      'Unable to authenticate in Identity Provider',
    ACS_PROCESS_ASSERTION_ERROR: 'Cannot find ACS process for the plugin',
    RELAY_STATE_ERROR: 'RELAY_STATE_ERROR',
    INVALID_ACCESS_TOKEN: 'Invalid access token.',
    CORPORATE_TITLE_KEY: 'Login with Corporate Credentials',
    CORPORATE_CREDENTIALS_KEY: 'Corporate credentials',
    UNEXPECTED_ERROR: 'An unexpected error has ocurred',
    CORPORATE_CREDENTIALS_DESCRIPTION_KEY:
      'To access Autosky using Corporate Credentials, enter the field below or the Corporate Environment code provided by your Administrator',
    SSO_INVALID_ACCESS_TOKEN: 'Invalid access token, please re-login.',
    SSO_CORPORATE_CREDENTIALS_NOT_FOUND: 'Corporate credential not found.',
    SSO_ERROR_ON_GET_CORPORATE_CREDENTIALS:
      'Error getting corporate credential, please contact administrator.',
    SSO_ERROR_ON_PREPARING_CORPORATE_CREDENTIALS:
      'Error getting corporate credential identified, please contact administrator.',
    SSO_CORPORATE_CREDENTIAL_NOT_ENABLED:
      'Identified corporate credential is not active, please contact the administrator.',
    SSO_AUTH_LOGIN_REDIRECT_ERROR:
      'Error redirecting to external corporate credentials site, please contact administrator.',
    SSO_ACS_PROCESS_ASSERTION_ERROR:
      'Error processing external corporate credential response, please contact administrator.',
    SSO_ACS_RESPONSE_ASSERTION_ERROR:
      'Corporate credential response document is invalid, please contact the administrator.',
    SSO_ACS_RESPONSE_HAS_EXPIRED:
      'Corporate credential login process timed out, please try again.',
    SSO_ACS_RESPONSE_IS_INVALID:
      'Error validating corporate credential response, please try again.',
    SSO_ERROR_ON_GET_SAML_ATTRIBUTES:
      'Error getting user details via corporate credential, please try again.',
    SSO_MISSING_SAML_NAME_ID_CLAIM:
      'External corporate credential system did not return [Name ID] attribute with unique user name, login or UPN. Please contact administrator.',
    SSO_MISSING_SAML_GIVEN_NAME_CLAIM:
      "The external corporate credential system did not return the [Given Name] attribute with the user's first name. Please contact the administrator.",
    SSO_MISSING_SAML_SURNAME_CLAIM:
      'The external corporate credential system did not return the [Surname] attribute with the last name of the user. Please contact the administrator.',
    SSO_MISSING_SAML_EMAIL_ADDRESS_CLAIM:
      "The external corporate credential system did not return the [Email] attribute with the user's email address. Please contact the administrator.",
    SSO_SAML_NAME_ID_CLAIM_TOO_LARGE:
      "The external corporate credential system returned the [Name ID] attribute with the user's unique name, login or UPN, with more characters allowed. Please contact the administrator.",
    SSO_SAML_GIVEN_NAME_CLAIM_TOO_LARGE:
      "The external corporate credential system returned the attribute [Given Name] with the user's first name, with more characters allowed. Please contact the administrator.",
    SSO_SAML_SURNAME_CLAIM_TOO_LARGE:
      'The external corporate credential system returned the attribute [Surname] with the last name of the user, with more characters allowed. Please contact the administrator.',
    SSO_SAML_EMAIL_ADDRESS_CLAIM_TOO_LARGE:
      "The external corporate credential system returned the [Email] attribute with the user's email address, with more characters allowed. Please contact the administrator.",
    SSO_USER_IS_BEING_CREATED_WAIT_IT:
      'Corporate credential user is already being created in another session, please try again.',
    USER_NOT_PERMITTED_SSO_CLIENT:
      'Access could not be established because the client has enabled the configuration for using corporate credentials.',
    USER_NOT_PERMITTED_SSO_ENABLED:
      'Invalid access using corporate credentials.',
    USER_ACCESS_IS_RESTRICTED: 'User access is restricted',
    ACCESS_RESTRICTED_TITLE_KEY: 'You are not authorized to access now',
    ACCESS_RESTRICTED_IP_KEY:
      'Check with the system administrator if your IP address {{ip}} has access to the platform',
    ACCESS_RESTRICTED_SCHEDULE_KEY:
      'Your access will be released at {{start}} ({{timeZone}}), according to the settings defined by the account administrator',
    DO_YOU_CHANGE_YOUR_PASSWORD:
      'Do you want to reset your access password now?',
    CHANGE_PASSWORD_NOW: 'Yes, set now',
    NOT_CHANGE_PASSWORD_NOW: 'No, set later',
    DAYS_TO_CHANGE_PASSWORD: 'Your password will expire in {{days}} {{letter}}',
    DAY_KEY: 'day',
    DAYS_KEY: 'days',
    SSO_ERROR_ON_VALIDATE_USER_GROUPS_WRONG_CONFIG:
      'Error getting corporate credential to validate groups. Invalid configuration. Please contact administrator.',
    SSO_ERROR_ON_VALIDATE_USER_GROUPS_MISSING_ATTRIBUTE:
      "Error getting corporate credential to validate groups. The attribute 'Group' is missing. Please contact administrator.",
    SSO_ERROR_ON_VALIDATE_USER_GROUPS_UNKNOWN_FORMAT:
      'Error getting corporate credential to validate groups. The attribute is in an unknown format. Please contact administrator.',
    SSO_ERROR_ON_VALIDATE_USER_GROUPS_FORBIDDEN:
      'Error getting corporate credential to validate groups. The user has no access. Please contact administrator.',

    FIRST_ACCESS_CONFIRM_KEY: 'Install now',
    FIRST_ACCESS_CANCEL_KEY: 'I already have the plugin installed',
    COPY_KEY: 'Copy',
    COPIED_KEY: 'Copied',
    CLICK_TO_COPY_KEY: 'Click here to copy',
    MFA_TYPE_ERROR: 'Incorrect authentication type',
    DUO_SETTINGS_NOT_FOUND: 'DUO authentication settings not found',
    DUO_STATE_MISMATCH: 'Incorrect DUO access token',
    DUO_ERROR_TO_AUTHENTICATE: 'DUO user authentication error',
    DESKTOP_APPS_KEY: 'Desktop Applications',
    DEFAULT_ACCESS_KEY: 'Default access',
    WEB_APPS_KEY: 'Web Applications',
    AUTHENTICATE_TO_CHANGE_PASSWORD_KEY:
      'Authenticate to validate password change',
    AUTHENTICATE_KEY: 'Authenticate',
    CURRENT_PASSWORD_KEY: 'Current password',
    VIRTUALIZER_NOT_FOUND_KEY: 'The current virtualizer is not supported by the system.',
    MFA_ERROR_KEY: 'Error authenticating MFA. Try again',
    ERROR_TO_ACCESS_ENVIRONMENT_KEY: 'An error occurred while accessing the environment. Please try again.',
  },
};
