/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable indent */
import { push } from 'connected-react-router';
import i18n from 'i18n';
import decoded from 'jwt-decode';
import { AxiosResponse } from 'axios';
import { messagesRequest } from 'modules/messages/actions';
import { all, put, takeLatest } from 'redux-saga/effects';
import { isRequestOK } from 'utils/helpers';
import {
  authChangePasswordFulfilled,
  authChangePasswordRejected,
  authChangePasswordRequest,
  authCheckRestrictionsFulfilled,
  authCheckRestrictionsRejected,
  authCheckRestrictionsRequest,
  authClearFields,
  authenticateSSORejected,
  authenticateSSORequest,
  authforgotPasswordFulfilled,
  authforgotPasswordRejected,
  authForgotPasswordRequest,
  authLoginFulfilled,
  authLoginRejected,
  authLoginRequest,
  authLoginRequiredMFAFulfilled,
  authLoginRequiredMFARejected,
  authLoginRequiredMFARequest,
  authLogout,
  authLogoutFulfilled,
  authLogoutRejected,
  authLogoutRequest,
  authRegisterFulfilled,
  authRegisterRejected,
  authRegisterRequest,
  checkLoginSSORequest,
  getTotpQrcodeFulfilled,
  getTotpQrcodeRejected,
  getTotpQrcodeRequest,
  keepLoginSSOFulfilled,
  keepLoginSSOReject,
  authLoginCiscoDuoRequest,
  enableAutoLogin,
  authCheckChangePasswordFulfilled,
  authCheckChangePasswordRejected,
  authCheckChangePasswordRequest,
} from './actions';
import endpoints, { api } from './endpoints';
import { AuthLoginResponse } from './interfaces';

function* authLoginRequestSaga({ payload }) {
  try {
    const { status, data }: AxiosResponse<AuthLoginResponse> = yield api.post({
      url: endpoints.login(),
      data: payload,
    });

    const isDefaultMfaRequired =
      data.mfa_required === true && data.mfa_type === 'default';

    if (
      data.mfa_required === false &&
      data?.password_expiration?.will_expire_soon
    ) {
      return yield all([
        put(authLoginFulfilled(data)),
        put(push('/password/expired')),
      ]);
    }

    if (isDefaultMfaRequired) {
      const path = data.mfa_configured ? '/requiredMFA' : '/configurationMFA';
      return yield isRequestOK(status)
        ? all([
            put(authClearFields()),
            put(authLoginFulfilled(data)),
            put(push(path)),
          ])
        : all([put(authLoginRejected())]);
    } else if (data.mfa_type === 'duo' && data.duo.redirect_url) {
      yield isRequestOK(status)
        ? all([put(authClearFields()), put(authLoginFulfilled(data))])
        : all([put(authLoginRejected())]);

      window.location.href = data.duo.redirect_url;
      return;
    } else {
      return yield isRequestOK(status)
        ? all([
            put(authClearFields()),
            put(authLoginFulfilled(data)),
            put(push('/home/environments')),
          ])
        : all([put(authLoginRejected())]);
    }
  } catch (e) {
    if (e?.response.data.errors[0] === 'INVALID_RECAPTCHA') {
      return yield all([
        put(authLoginRejected()),
        put(
          messagesRequest({
            type: 'error',
            message: i18n.t('RECAPTCHA_ERROR_KEY'),
          })
        ),
      ]);
    }
    if (e?.response.data.errors[0] === 'PASSWORD_EXPIRED') {
      const { user_uuid, token } = e?.response.data;

      return yield all([
        put(authLoginRejected()),
        put(
          messagesRequest({
            type: 'info',
            message: i18n.t(e?.response.data.errors),
          })
        ),
        put(enableAutoLogin()),
        put(push(`/register/${user_uuid}/${token}`)),
      ]);
    } else {
      return yield all([
        put(authLoginRejected()),
        put(
          messagesRequest({
            type: 'error',
            message: i18n.t(e?.response.data.errors),
          })
        ),
      ]);
    }
  }
}

function* authCheckRestrictionsRequestSaga({ payload }) {
  try {
    const { status, data } = yield api.post({
      url: endpoints.checkRestrictions(),
      data: payload,
    });

    return yield isRequestOK(status)
      ? put(authCheckRestrictionsFulfilled(data))
      : put(authCheckRestrictionsRejected());
  } catch (e) {
    return yield put(authCheckRestrictionsRejected());
  }
}

function* authLogoutRequestSaga({ payload }) {
  const tokenObject: TokenObjectProps = decoded(payload);
  try {
    if (tokenObject.identity.is_sso_user) {
      const { status, data } = yield api.post({
        url: endpoints.logoutSSO(),
      });
      window.location.href = data?.redirect_url;
      return yield isRequestOK(status)
        ? put(authLogoutFulfilled())
        : put(authLogoutRejected());
    }

    return yield put(authLogout());
  } catch (e) {
    return yield put(authLogoutRejected());
  }
}

function* authLoginRequiredMFARequestSaga({ payload }) {
  const { values, passwordWillExpireSoon } = payload;
  try {
    const { status, data } = yield api.post({
      url: endpoints.loginOtp(),
      data: values,
    });

    if (passwordWillExpireSoon && status === 200) {
      return yield all([
        put(authLoginRequiredMFAFulfilled(data)),
        put(push('/password/expired')),
      ]);
    }

    return yield isRequestOK(status)
      ? all([
          put(authClearFields()),
          put(authLoginRequiredMFAFulfilled(data)),
          put(push('/home/environments')),
        ])
      : all([put(authLoginRejected())]);
  } catch (e) {
    return yield all([
      put(authLoginRequiredMFARejected()),
      put(
        messagesRequest({
          type: 'error',
          message: i18n.t(
            e?.response?.status === 404 ? 'WRONG_USER_KEY' : 'MFA_ERROR_KEY'
          ),
        })
      ),
      put(push('/requiredMFA')),
    ]);
  }
}

function* getTotpQrcodeRequestSaga() {
  try {
    const { status, data } = yield api.get({
      url: endpoints.totpQrcode(),
    });

    return yield isRequestOK(status)
      ? all([put(authClearFields()), put(getTotpQrcodeFulfilled(data))])
      : all([put(getTotpQrcodeRejected())]);
  } catch (e) {
    return yield all([
      put(getTotpQrcodeRejected()),
      put(
        messagesRequest({
          type: 'error',
          message: i18n.t(
            e?.response?.status === 404 ? 'WRONG_USER_KEY' : 'ERROR_REQUEST_KEY'
          ),
        })
      ),
    ]);
  }
}

function* authForgotPasswordRequestSaga({ payload }) {
  try {
    const { status } = yield api.post({
      url: endpoints.resetPassword(),
      data: payload,
    });

    return yield isRequestOK(status)
      ? all([
          put(authforgotPasswordFulfilled()),
          put(
            messagesRequest({
              message: i18n.t('VERIFY_YOUR_EMAIL_KEY'),
              type: 'success',
            })
          ),
          put(push('/')),
        ])
      : all([put(authforgotPasswordRejected())]);
  } catch (e) {
    return yield all([
      put(authforgotPasswordRejected()),
      put(
        messagesRequest({
          type: 'error',
          message: i18n.t(
            e?.response?.status === 404 ? 'WRONG_USER_KEY' : 'ERROR_REQUEST_KEY'
          ),
        })
      ),
    ]);
  }
}

interface TokenObjectProps {
  identity: {
    errors: string[];
    is_sso_user: boolean;
    status: string;
  };
}

interface KeepLoginProps {
  payload: string;
}

function* checkLoginSSORequestSaga({ payload }: KeepLoginProps) {
  try {
    const tokenObject: TokenObjectProps = decoded(payload);

    if (
      tokenObject.identity.errors &&
      tokenObject.identity.errors.length !== 0
    ) {
      return yield all([
        put(keepLoginSSOReject()),
        put(
          messagesRequest({
            type: 'error',
            message: i18n.t(tokenObject.identity.errors[0]),
          })
        ),
        put(
          push(
            tokenObject.identity.status === 'SSO_FORCE_USER_LOGOUT'
              ? '/home/environments'
              : '/sso'
          )
        ),
      ]);
    }

    if (tokenObject.identity.status === 'SSO_FORCE_USER_LOGOUT') {
      return yield all([put(authLogout())]);
    }

    const { status } = yield api.post({
      url: endpoints.tokenValidateSSO(),
      data: {
        access_token: payload,
      },
    });
    return yield isRequestOK(status) &&
      all([
        put(keepLoginSSOFulfilled({ access_token: payload })),
        put(push('/home/environments')),
      ]);
  } catch (e) {
    return yield all([
      put(keepLoginSSOReject()),
      put(
        messagesRequest({
          type: 'error',
          message: i18n.t(
            e?.response?.status === 404 ? 'WRONG_USER_KEY' : 'ERROR_REQUEST_KEY'
          ),
        })
      ),
      put(push('/')),
    ]);
  }
}

function* authChangePasswordRequestSaga({ payload }) {
  try {
    const { status } = yield api.post({
      url: endpoints.changePassword(),
      data: {
        email: payload.email,
        old_password: payload.oldPassword,
        password: payload.password,
        password_confirmation: payload.confirmPassword,
        recaptcha_token: payload.recaptcha_token,
      },
    });

    return yield isRequestOK(status)
      ? all([
          put(authChangePasswordFulfilled()),
          put(
            messagesRequest({
              message: i18n.t('UPDATED_ACCOUNT_KEY'),
              type: 'success',
            })
          ),
          put(push('/')),
        ])
      : all([put(authChangePasswordRejected())]);
  } catch (e) {
    return yield all([
      put(
        messagesRequest({
          message: i18n.t('EMAIL_OR_PASSWORD_DONT_MATCH_KEY'),
          type: 'error',
        })
      ),
      put(authChangePasswordRejected()),
    ]);
  }
}

function* authRegisterRequestSaga({ payload }) {
  const { autoLoginRequest, ...values } = payload;
  try {
    const { status, data } = yield api.post({
      url: endpoints.register(),
      data: values,
    });

    return yield isRequestOK(status)
      ? all([
          put(authRegisterFulfilled(data)),
          put(
            messagesRequest({
              type: 'success',
              message: i18n.t('UPDATED_ACCOUNT_KEY'),
            })
          ),
          autoLoginRequest ? autoLoginRequest() : put(push('/')),
        ])
      : put(authRegisterRejected());
  } catch (e) {
    return yield all([
      put(authRegisterRejected()),
      put(
        messagesRequest({
          message: i18n.t('ERROR_CHANGING_PASSWORD_KEY'),
          type: 'error',
        })
      ),
    ]);
  }
}

function* authenticateRequestSSOSaga({ payload }) {
  try {
    const { data }: any = yield api.post({
      url: endpoints.loginSSO(),
      data: payload,
    });
    window.location.href = data?.redirect_url;
  } catch (error) {
    return yield all([
      put(authenticateSSORejected()),
      put(
        messagesRequest({
          type: 'error',
          message: i18n.t(
            error?.response?.data?.errors[0] || 'UNEXPECTED_ERROR'
          ),
        })
      ),
    ]);
  }
}

function* authLoginCiscoDuoRequestSaga({ payload }) {
  const { values } = payload;

  try {
    const { status, data } = yield api.post({
      url: endpoints.loginCiscoDuo(),
      data: values,
    });

    return yield isRequestOK(status)
      ? all([
          put(authLoginRequiredMFAFulfilled(data)),
          put(push('/home/environments')),
        ])
      : all([put(authLoginRejected())]);
  } catch (e) {
    return yield all([put(authLoginRequiredMFARejected())]);
  }
}

function* authCheckChangePasswordRequestSaga({ payload }) {
  const { dataToSend, setIsAuthenticated } = payload;

  try {
    const { status, data } = yield api.post({
      url: endpoints.authCheckChangePassword(),
      data: dataToSend,
    });

    if (isRequestOK(status)) {
      yield all([put(authCheckChangePasswordFulfilled(data))]);
      setIsAuthenticated(true);
    } else {
      yield all([
        put(authCheckChangePasswordRejected()),
        put(
          messagesRequest({
            type: 'error',
            message: i18n.t('AUTH_FAILURE'),
          })
        ),
      ]);
    }
  } catch (e) {
    yield all([
      put(authCheckChangePasswordRejected()),
      put(
        messagesRequest({
          type: 'error',
          message: i18n.t('AUTH_FAILURE'),
        })
      ),
    ]);
  }
}

export default [
  takeLatest(authLoginRequest, authLoginRequestSaga),
  takeLatest(authCheckRestrictionsRequest, authCheckRestrictionsRequestSaga),
  takeLatest(authForgotPasswordRequest, authForgotPasswordRequestSaga),
  takeLatest(authChangePasswordRequest, authChangePasswordRequestSaga),
  takeLatest(authRegisterRequest, authRegisterRequestSaga),
  takeLatest(authLoginRequiredMFARequest, authLoginRequiredMFARequestSaga),
  takeLatest(getTotpQrcodeRequest, getTotpQrcodeRequestSaga),
  takeLatest(authenticateSSORequest, authenticateRequestSSOSaga),
  takeLatest(checkLoginSSORequest, checkLoginSSORequestSaga),
  takeLatest(authLogoutRequest, authLogoutRequestSaga),
  takeLatest(authLoginCiscoDuoRequest, authLoginCiscoDuoRequestSaga),
  takeLatest(
    authCheckChangePasswordRequest,
    authCheckChangePasswordRequestSaga
  ),
];
